interface PricingWidgetOutput {
  access_control: string | null;
  data_variable_element: string;
  default_ui_visibility_pw_quote_and_sd_output: string;
  default_ui_visibility_pw_quote_only_output: string;
  input_output: string;
  pw_quote_output_only_order: number | null;
  pw_system_quote_and_sd_order: number | null;
  root: string | null;
  sf_object: string | null;
  source: string | null;
  ui_name: string | null;
  value_type: string | null;
}

export const outputsConstants: PricingWidgetOutput[] = [
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_term_yrs_num",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 20.0,
    pw_system_quote_and_sd_order: 20.0,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Term",
    value_type: "Picklist (15),(20),(25)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_add_term_periods1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_add_term_periods2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_add_term_ext_length1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_add_term_ext_length2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_add_term_periods1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (18,0)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_add_term_periods2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (18,0)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_add_term_ext_length1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (18,0)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_add_term_ext_length2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (18,0)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr1",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 23.0,
    pw_system_quote_and_sd_order: 23.0,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Payment Year 1 ",
    value_type: "Currency (7,0)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr2",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr3",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr3",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr4",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr4",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr5",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr5",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr6",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr6",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr7",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr7",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr8",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr8",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr9",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr9",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr10",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr10",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr11",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr11",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr12",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr12",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr13",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr13",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr14",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr14",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr15",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr15",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr16",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr16",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr17",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr17",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr18",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr18",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr19",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr19",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr20",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr20",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr21",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr21",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr22",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr22",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr23",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr23",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr24",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr24",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_annual_rent_yr25",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Annual Rent Yr25",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_escal_orig_term",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 25.0,
    pw_system_quote_and_sd_order: 25.0,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Escalator",
    value_type: "Picklist Percent (0.00%),(0.49%),(0.99%),(1.49%),(1.99%),(2.49%),(2.75%),(2.99%)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr1_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PV Fcst Prod Yr1 Annual",
    value_type: "Decimal (7,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr2_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr3_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr4_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr5_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr6_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr7_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr8_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr9_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr10_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr11_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr12_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr13_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr14_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr15_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr16_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr17_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr18_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr19_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr20_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr21_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr22_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr23_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr24_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_fcst_prod_yr25_annual",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr3",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr4",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr5",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr6",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr7",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr8",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr9",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr10",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr11",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr12",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr13",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr14",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr15",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr16",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr17",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr18",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr19",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr20",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr21",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr22",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr23",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr24",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "guaranty_prod_yr25",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 17,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Rate ($/kWh) Year 1",
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr3",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr4",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr5",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr6",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr7",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr8",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr9",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr10",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr11",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr12",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr13",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr14",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr15",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr16",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr17",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr18",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr19",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr20",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr21",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr22",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr23",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr24",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_guaranty_pmt_rate_kwh_yr25",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr1",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr2",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr3",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr3",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr4",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr4",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr5",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr5",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr6",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr6",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr7",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr7",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr8",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr8",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr9",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr9",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr10",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr10",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr11",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr11",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr12",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr12",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr13",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr13",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr14",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr14",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr15",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr15",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr16",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr16",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr17",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr17",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr18",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr18",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr19",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr19",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr20",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr20",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr21",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr21",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr22",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr22",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr23",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr23",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr24",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr24",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_terminate_pmt_yr25",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Terminate Pmt Yr25",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_term_yrs_word",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_term_yrs_num",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 19.0,
    pw_system_quote_and_sd_order: 19.0,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Term",
    value_type: "Picklist (15),(20),(25)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr1_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 28.0,
    pw_system_quote_and_sd_order: 28.0,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Rate Year 1 ($/kWh)",
    value_type: "Decimal (1,4)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr2_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr2 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr3_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr3 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr4_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr4 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr5_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr5 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr6_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr6 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr7_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr7 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr8_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr8 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr9_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr9 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr10_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr10 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr11_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr11 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr12_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr12 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr13_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr13 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr14_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr14 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr15_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr15 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr16_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr16 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr17_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr17 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr18_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr18 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr19_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr19 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr20_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr20 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr21_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr21 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr22_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr22 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr23_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr23 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr24_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr24 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "dollars_kwh_yr25_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Dollars kWh Yr25 PPA",
    value_type: "Currency (1,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_escal_orig_term",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 24.0,
    pw_system_quote_and_sd_order: 24.0,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Escalator ",
    value_type: "Picklist Percent (0.00%),(0.49%),(0.99%),(1.49%),(1.99%),(2.49%),(2.75%),(2.99%)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr1",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr2",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr3",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr3",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr4",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr4",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr5",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr5",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr6",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr6",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr7",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr7",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr8",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr8",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr9",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr9",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr10",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr10",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr11",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr11",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr12",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr12",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr13",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr13",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr14",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr14",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr15",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr15",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr16",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr16",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr17",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr17",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr18",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr18",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr19",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr19",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr20",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr20",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr21",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr21",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr22",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr22",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr23",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr23",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr24",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr24",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_terminate_pmt_yr25",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Terminate Pmt Yr25",
    value_type: null
  },
  {
    access_control: "All SR Access",
    data_variable_element: "disc_to_pre_system_utility_bill_pcnt_out",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 27.0,
    pw_system_quote_and_sd_order: 27.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Annual Discount to Utility Bill (%)",
    value_type: "Percent (2,2)"
  },
  {
    access_control: "All SR Access",
    data_variable_element: "ppa_pmts_nom_yr1",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 22.0,
    pw_system_quote_and_sd_order: 22.0,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Payments Year 1",
    value_type: "Currency (7,0)"
  },
  {
    access_control: "All SR Access",
    data_variable_element: "est_bill_savings_yr1",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.0,
    pw_system_quote_and_sd_order: 27.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 1",
    value_type: "Currency (7,0)"
  },
  {
    access_control: "All SR Access",
    data_variable_element: "est_bill_savings_term_ppa",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 31.0,
    pw_system_quote_and_sd_order: 31.0,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings PPA Term",
    value_type: "Currency (7,0)"
  },
  {
    access_control: "All SR Access",
    data_variable_element: "est_bill_savings_term_lease",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 32.0,
    pw_system_quote_and_sd_order: 32.0,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Lease Term",
    value_type: "Currency (7,0)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_term_date_final",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Date"
  },
  {
    access_control: "All SR Access",
    data_variable_element: "lease_term_months",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 4,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "program_type_gpsg",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget ",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "turnkey_nom",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_3",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_4",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_5",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_6",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_7",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_8",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_9",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_10",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_11",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_12",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_13",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_14",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_15",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_16",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_17",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_18",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_19",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_20",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_21",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_22",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_23",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_24",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "yr_number_25",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr1",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr2",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr3",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr3",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr4",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr4",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr5",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr5",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr6",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr6",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr7",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr7",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr8",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr8",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr9",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr9",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr10",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr10",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr11",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr11",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr12",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr12",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr13",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr13",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr14",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr14",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr15",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr15",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr16",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr16",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr17",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr17",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr18",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr18",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr19",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr19",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr20",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr20",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr21",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr21",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr22",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr22",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr23",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr23",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr24",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr24",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_mo_rent_yr25",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Lease Mo Rent Yr25",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "financing_type",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 6.0,
    pw_system_quote_and_sd_order: 6.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Financing Type",
    value_type: "Picklist (PPA),(Lease)"
  },
  {
    access_control: null,
    data_variable_element: "itc_pcnt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 48.0,
    pw_system_quote_and_sd_order: 48.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "ITC Percentage",
    value_type: "Percent (2,0)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "itc_nom",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "turnkey_per_watt",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "fmv_per_watt",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "fmv_nom",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Finance Only.  Changing this without financing knowing creates downstream issues",
    data_variable_element: "degredation_pcnt",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Input/Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Degredation Percent",
    value_type: "Percent (1,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output ",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr3",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr4",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr5",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr6",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr7",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr8",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr9",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr10",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr11",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr12",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr13",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr14",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr15",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr16",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr17",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr18",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr19",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rec_rev_nom_yr20",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: null,
    data_variable_element: "epc_total_nom_sum",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "EPC Total Nom Sum",
    value_type: "Formula Decimal"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "sr_trans_legal_fees_per_watt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 56.0,
    pw_system_quote_and_sd_order: 56.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Transaction/Legal Fees ($/W)",
    value_type: "Currency (1,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "sr_finl_dev_fee_per_watt_out",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: 52.0,
    pw_system_quote_and_sd_order: 52.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Sunrock Financing Development Fee ($/W)",
    value_type: "Currency (1,4)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "sr_finl_dev_fee_per_watt_upfront",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 52.0,
    pw_system_quote_and_sd_order: 52.0,
    root: null,
    sf_object: "Budget",
    source: "Pricing Widget",
    ui_name: "Sunrock Upfront Financing Development Fee ($/W)",
    value_type: "Currency (1,4)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "channel_dev_cost_per_watt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 55.0,
    pw_system_quote_and_sd_order: 55.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Channel Referral Services ($/W)",
    value_type: "Currency (1,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_escal",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 58.0,
    pw_system_quote_and_sd_order: 59.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "O&M Escalator",
    value_type: "Percent (2,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr1_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 56.0,
    pw_system_quote_and_sd_order: 58.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "O&M Year 1 ($/kW) ",
    value_type: "Currency (1,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr2_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Opp Maintain Cost Yr2 ($/W)",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr3_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Opp Maintain Cost Yr3 ($/W)",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr4_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Opp Maintain Cost Yr4 ($/W)",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr5_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Opp Maintain Cost Yr5 ($/W)",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr6_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 57.0,
    pw_system_quote_and_sd_order: 57.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "O&M Years 6+ ($/kW) ",
    value_type: "Currency (1,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr7_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Opp Maintain Cost Yr7 ($/W)",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr8_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Opp Maintain Cost Yr8 ($/W)",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr9_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Opp Maintain Cost Yr9 ($/W)",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr10_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Opp Maintain Cost Yr10 ($/W)",
    value_type: null
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "opp_maintain_cost_yr11_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "O&M Cost Year 11 ($/W)",
    value_type: "Decimal"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ir_dev_cost",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: null,
    data_variable_element: "itc_adder_domestic_qt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 45.0,
    pw_system_quote_and_sd_order: 45.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Domestic ITC Adder Eligible",
    value_type: "Picklist (Yes),(No)"
  },
  {
    access_control: null,
    data_variable_element: "itc_adder_energy_com_qt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 46.0,
    pw_system_quote_and_sd_order: 46.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Energy Community ITC Adder Eligible",
    value_type: "Picklist (Yes),(No)"
  },
  {
    access_control: null,
    data_variable_element: "itc_adder_low_inc_qt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 47.0,
    pw_system_quote_and_sd_order: 47.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Low Income ITC Adder Eligible",
    value_type: "Picklist (Yes),(No)"
  },
  {
    access_control: null,
    data_variable_element: "sales_channel",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 4.0,
    pw_system_quote_and_sd_order: 4.0,
    root: null,
    sf_object: "Opportunity",
    source: "Salesforce=Opportunity",
    ui_name: "Sales Channel",
    value_type: "Picklist (Channel:Sunstone),Channel:Other),(DTC)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "lease_term_yrs_word",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: null
  },
  {
    access_control: "All SR Access",
    data_variable_element: "project_state",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 3.0,
    pw_system_quote_and_sd_order: 3.0,
    root: null,
    sf_object: "Opportunity",
    source: "Salesforce=Opportunity",
    ui_name: "State",
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "epc_total_per_watt_out_sd",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 32.0,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Sunrock Proposed EPC ($/W)",
    value_type: "Currency (1,2)"
  },
  {
    access_control: null,
    data_variable_element: "ppa_add_term_periods1_word",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "ppa_add_term_ext_length1_word",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "ppa_add_term_periods2_word",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "ppa_add_term_ext_length2_word",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "lease_add_term_periods1_word",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "lease_add_term_ext_length1_word",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "lease_add_term_periods2_word",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "lease_add_term_ext_length2_word",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "dollars_kwh_yr1_lease",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 29.0,
    pw_system_quote_and_sd_order: 29.0,
    root: "financing_type=Lease",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Rate Year 1 ($/kWh) Lease",
    value_type: "Currency (1,2)"
  },
  {
    access_control: null,
    data_variable_element: "disc_to_pre_system_utility_bill_pcnt_inp",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 67.0,
    pw_system_quote_and_sd_order: 67.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Annual Discount to Utility Bill (%) INPUT",
    value_type: "Percent (3,2)"
  },
  {
    access_control: null,
    data_variable_element: "epc_total_per_watt_inp_qt",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 33.0,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Installer Proposed EPC ($/W)",
    value_type: "Currency (1,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "financial_product_id",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 7.0,
    pw_system_quote_and_sd_order: 7.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Financial Product ID",
    value_type: "Text"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr1",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Guarantee Rate ($/kWh) Year 1",
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr2",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr3",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr4",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr5",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr6",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr7",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr8",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr9",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr10",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr11",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr12",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr13",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr14",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr15",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr16",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr17",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr18",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr19",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr20",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr21",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr22",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr23",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr24",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_guaranty_pmt_rate_kwh_yr25",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Currency"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_term_date_final",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Date"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ppa_term_months",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Number (Decimal 3,0)"
  },
  {
    access_control: null,
    data_variable_element: "quote_generation_date",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Quote Generation Date",
    value_type: null
  },
  {
    access_control: null,
    data_variable_element: "kwh_per_kw_qt",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 13.0,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Productivity (kWh/kW)",
    value_type: "Decimal (4,1)"
  },
  {
    access_control: null,
    data_variable_element: "pv_size_dc_kw_qt",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 10.0,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PV System Size (kWdc)",
    value_type: "Decimal (7,3)"
  },
  {
    access_control: null,
    data_variable_element: "avoided_cost_per_kwh_qt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 26.0,
    pw_system_quote_and_sd_order: 26.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Avoided Cost Rate ($/kWh)",
    value_type: "Decimal (1,4)"
  },
  {
    access_control: null,
    data_variable_element: "kwh_yr1_qt",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: null,
    value_type: "Decimal (7,1)"
  },
  {
    access_control: null,
    data_variable_element: "state_incentive_yr1_nom_qt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 42.0,
    pw_system_quote_and_sd_order: 42.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "State Incentive Upfront $",
    value_type: "Currency (7,2)"
  },
  {
    access_control: null,
    data_variable_element: "epc_delta_sr_epc_inst_epc_per_watt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 38.0,
    pw_system_quote_and_sd_order: 38.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Sunrock vs Installer EPC Delta $",
    value_type: "Currency (7,2)"
  },
  {
    access_control: null,
    data_variable_element: "sr_trans_legal_fees_nom",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 59.0,
    pw_system_quote_and_sd_order: 59.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Transaction/Legal Fees $",
    value_type: "Currency (7,2)"
  },
  {
    access_control: null,
    data_variable_element: "sr_finl_dev_fee_per_watt_inp",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Input/Output",
    pw_quote_output_only_order: 53.0,
    pw_system_quote_and_sd_order: 53.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Sunrock Financing Development Fee ($/W) INPUT",
    value_type: "Currency (1,4)"
  },
  {
    access_control: null,
    data_variable_element: "sr_finl_dev_fee_nom",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: 57.0,
    pw_system_quote_and_sd_order: 57.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Sunrock Dev Fee $",
    value_type: "Currency (7,2)"
  },
  {
    access_control: null,
    data_variable_element: "channel_dev_cost_nom",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 58.0,
    pw_system_quote_and_sd_order: 58.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Channel Referral Services $",
    value_type: "Currency (7,2)"
  },
  {
    access_control: null,
    data_variable_element: "dev_and_ch_ref_nom",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: 60.0,
    pw_system_quote_and_sd_order: 60.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Sunrock + Channel Dev Fees $",
    value_type: "Currency (7,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "support_structure_type_primary_qt",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 9.0,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Opportunity",
    source: "Salesforce=Opportunity",
    ui_name: "Primary Support Structure Type",
    value_type: "Picklist  (Rooftop Solar),(Carport Solar),(Ground mount Solar)"
  },
  {
    access_control: null,
    data_variable_element: "ppa_prod_guar_included",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 8.0,
    pw_system_quote_and_sd_order: 8.0,
    root: "financing_type=PPA",
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "PPA Production Guaranty Included",
    value_type: "Picklist (Yes),(No)"
  },
  {
    access_control: null,
    data_variable_element: "solver",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output\n",
    pw_quote_output_only_order: 5.0,
    pw_system_quote_and_sd_order: 5.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Solver",
    value_type: "Text"
  },
  {
    access_control: "No Editing of formula field by user in PW UI",
    data_variable_element: "ac_system_size_estimated",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 12.0,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated AC System Size",
    value_type: "Decimal (5,3)"
  },
  {
    access_control: null,
    data_variable_element: "ac_system_size_multiplier",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 11.0,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "DC to AC Multiplayer",
    value_type: "Decimal (1,2)"
  },
  {
    access_control: null,
    data_variable_element: "rec_type",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 40.0,
    pw_system_quote_and_sd_order: 40.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "REC Type",
    value_type: "Picklist"
  },
  {
    access_control: null,
    data_variable_element: "rec_group",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 41.0,
    pw_system_quote_and_sd_order: 41.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "REC Group",
    value_type: "Picklist"
  },
  {
    access_control: null,
    data_variable_element: "public_school",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 44.0,
    pw_system_quote_and_sd_order: 44.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Public School",
    value_type: "Picklist"
  },
  {
    access_control: null,
    data_variable_element: "public_entity",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 45.0,
    pw_system_quote_and_sd_order: 45.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Public Entity",
    value_type: "Picklist"
  },
  {
    access_control: null,
    data_variable_element: "utility_name",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 43.0,
    pw_system_quote_and_sd_order: 43.0,
    root: null,
    sf_object: "Opportunity",
    source: "Salesforce=Opportunity",
    ui_name: "Utility Name",
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "pricing_model_error",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Input/Output\n",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: null,
    source: null,
    ui_name: null,
    value_type: "Text"
  },
  {
    access_control: null,
    data_variable_element: "itc_ineligible_nom_qt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 35.0,
    pw_system_quote_and_sd_order: 35.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "ITC Ineligible Cost ($)",
    value_type: "Currency (7,2)"
  },
  {
    access_control: null,
    data_variable_element: "custom_useful_life_term",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 21.0,
    pw_system_quote_and_sd_order: 21.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Custom Useful Life Term",
    value_type: "Decimal (2,0)"
  },
  {
    access_control: null,
    data_variable_element: "offtaker_holds_rec_rights",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output\n",
    pw_quote_output_only_order: 42.0,
    pw_system_quote_and_sd_order: 42.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Offtaker Holds REC Rights",
    value_type: "Picklist"
  },
  {
    access_control: null,
    data_variable_element: "pricing_model_upfront_incentive",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 47.0,
    pw_system_quote_and_sd_order: 47.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Pricing Model Upfront Incentive ($)",
    value_type: "Currency (7,2)"
  },
  {
    access_control: null,
    data_variable_element: "custom_opp_maintain_cost_applies",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 63.0,
    pw_system_quote_and_sd_order: 63.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Custom O&M",
    value_type: "Picklist"
  },
  {
    access_control: null,
    data_variable_element: "custom_opp_maintain_cost_yr1_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 64.0,
    pw_system_quote_and_sd_order: 64.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Custom O&M Cost Year 1 ($/kW)",
    value_type: "Currency (1,2)"
  },
  {
    access_control: null,
    data_variable_element: "custom_opp_maintain_cost_yr6_per_kw",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 65.0,
    pw_system_quote_and_sd_order: 65.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Custom O&M Cost Year 6 ($/kW)",
    value_type: "Currency (1,2)"
  },
  {
    access_control: null,
    data_variable_element: "custom_opp_maintain_cost_escal",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 66.0,
    pw_system_quote_and_sd_order: 66.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Custom O&M Cost Escalator",
    value_type: "Picklist"
  },
  {
    access_control: null,
    data_variable_element: "site_lease_included",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Site Lease",
    value_type: "Picklist"
  },
  {
    access_control: null,
    data_variable_element: "site_lease_annual_nom",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 34.0,
    pw_system_quote_and_sd_order: 34.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Annual Site Lease ($/Yr)",
    value_type: "Currency (7,2)"
  },
  {
    access_control: null,
    data_variable_element: "site_lease_term",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 35.0,
    pw_system_quote_and_sd_order: 35.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Site Lease Term",
    value_type: "Decimal (2,0)"
  },
  {
    access_control: null,
    data_variable_element: "site_lease_escal",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output\n",
    pw_quote_output_only_order: 36.0,
    pw_system_quote_and_sd_order: 36.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Site Lease Escalator",
    value_type: "Picklist"
  },
  {
    access_control: null,
    data_variable_element: "partner_contract_form_applies",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Input/Output\n",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Opportunity",
    source: "Salesforce=Opportunity",
    ui_name: null,
    value_type: "Picklist"
  },
  {
    access_control: "No Editing of formula field by user in PW UI",
    data_variable_element: "ac_system_size_kw",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Input/Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: 14.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "AC System Size (kW)",
    value_type: "Decimal (7,3)"
  },
  {
    access_control: null,
    data_variable_element: "inverter_extended_warr_per_watt",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Input/Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: 54.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Inverter Extended Warranty ($/W)",
    value_type: "Currency (1,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "kwh_yr1_sd",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "kWh Yr1",
    value_type: "Formula Decimal"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "rooftop_pv_dc_kw",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 10.0,
    pw_system_quote_and_sd_order: 10.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Rooftop Solar (kWdc)",
    value_type: "Decimal (7,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "carport_pv_dc_kw",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 11.0,
    pw_system_quote_and_sd_order: 11.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Carport Solar (kWdc)",
    value_type: "Decimal (7,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "ground_mount_pv_dc_kw",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 12.0,
    pw_system_quote_and_sd_order: 12.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Ground Mount Solar (kWdc)",
    value_type: "Decimal (7,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "pv_size_dc_kw_sd",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Input/Output",
    pw_quote_output_only_order: 13.0,
    pw_system_quote_and_sd_order: 13.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "PV System Size (kWdc)",
    value_type: "Decimal (7,3)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "battery_storage_incl",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: 16.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Battery Storage Included",
    value_type: "Picklist (Yes),(No)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "battery_pwr_rating_kw",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: 17.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Battery Power Rating (kW)",
    value_type: "Decimal (4,1)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "battery_energy_rating_kwh",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: 18.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Battery Energy Rating (kWh)",
    value_type: "Decimal (7,1)"
  },
  {
    access_control: null,
    data_variable_element: "state_incentive_yr1_nom_sd",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Input/Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: 46.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "State Incentive Upfront $",
    value_type: "Currency (7,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "support_structure_type_primary_sd",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Input/Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: 9.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Product System Type",
    value_type: "Picklist  (Rooftop Solar),(Carport Solar),(Ground mount Solar)"
  },
  {
    access_control: null,
    data_variable_element: "epc_total_per_watt_sd",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Input/Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: 37.0,
    root: null,
    sf_object: "System Design",
    source: null,
    ui_name: "EPC ($/W)",
    value_type: "Currency (1,2)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "kwh_per_kw_sd",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Input/Output ",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: 15.0,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Productivity (kWh/kW)",
    value_type: "Decimal (4,1)"
  },
  {
    access_control: null,
    data_variable_element: "sd_status",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design",
    source: null,
    ui_name: null,
    value_type: "Picklist"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "desc_premisis",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Description of Premises",
    value_type: "Text Area (255)"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "monitoring_platform_name",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=Opportunity",
    ui_name: "Monitoring Platform Name",
    value_type: "Solaredge\nSMA Sunny Portal\nEnphase Enlighten\nAlsoEnergy PowerTrack\nSolar Log\n"
  },
  {
    access_control: "Engineering & Finance Only",
    data_variable_element: "monitoring_system_id",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=Opportunity",
    ui_name: "Monitoring System ID",
    value_type: null
  },
  {
    access_control: null,
    data_variable_element: "pv_module1_watt_class",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Module1 Watt Class",
    value_type: "Decimal (3,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_module2_watt_class",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Module2 Watt Class",
    value_type: "Decimal (3,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_module3_watt_class",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Module3 Watt Class",
    value_type: "Decimal (3,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_inverter1_power_rating_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Inverter1 Power Rating (kW)",
    value_type: "Decimal (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_inverter2_power_rating_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Inverter2 Power Rating (kW)",
    value_type: "Decimal (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_inverter3_power_rating_kw",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Inverter3 Power Rating (kW)",
    value_type: "Decimal (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_module1_quantity",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Module1 Quantity",
    value_type: "Decimal (18,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_module2_quantity",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Module2 Quantity",
    value_type: "Decimal (18,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_module3_quantity",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Module3 Quantity",
    value_type: "Decimal (18,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_inverter1_quantity",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Inverter1 Quantity",
    value_type: "Decimal (4,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_inverter2_quantity",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Inverter2 Quantity",
    value_type: "Decimal (4,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_inverter3_quantity",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design ",
    source: "Salesforce=SystemDesign",
    ui_name: "PV Inverter3 Quantity",
    value_type: "Decimal (4,0)"
  },
   {
    access_control: null,
    data_variable_element: "pv_module_quanty_total_contract",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Total PV Module Quantity",
    value_type: "Decimal (5,0)"
  },
  {
    access_control: null,
    data_variable_element: "pv_inverter_quanty_total_contract",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "System Design",
    source: "Salesforce=SystemDesign",
    ui_name: "Total PV Inverter Quantity",
    value_type: "Decimal (4,0)"
  },
  {
    access_control: null,
    data_variable_element: "irr_class_b_investor_member",
    default_ui_visibility_pw_quote_and_sd_output: "No",
    default_ui_visibility_pw_quote_only_output: "No",
    input_output: "Output",
    pw_quote_output_only_order: null,
    pw_system_quote_and_sd_order: null,
    root: null,
    sf_object: "Quote",
    source: "Salesforce:Quote",
    ui_name: "Class B Investor Member IRR",
    value_type: "Percent (2,3)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr2",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.02,
    pw_system_quote_and_sd_order: 27.02,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 2",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr3",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.03,
    pw_system_quote_and_sd_order: 27.03,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 3",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr4",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.04,
    pw_system_quote_and_sd_order: 27.04,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 4",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr5",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.05,
    pw_system_quote_and_sd_order: 27.05,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 5",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr6",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.06,
    pw_system_quote_and_sd_order: 27.06,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 6",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr7",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.07,
    pw_system_quote_and_sd_order: 27.07,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 7",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr8",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.08,
    pw_system_quote_and_sd_order: 27.08,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 8",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr9",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.09,
    pw_system_quote_and_sd_order: 27.09,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 9",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr10",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 30.0,
    pw_system_quote_and_sd_order: 30.0,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 10",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr11",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.11,
    pw_system_quote_and_sd_order: 27.11,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 11",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr12",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.12,
    pw_system_quote_and_sd_order: 27.12,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 12",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr13",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.13,
    pw_system_quote_and_sd_order: 27.13,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 13",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr14",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.14,
    pw_system_quote_and_sd_order: 27.14,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 14",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr15",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.15,
    pw_system_quote_and_sd_order: 27.15,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 15",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr16",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.16,
    pw_system_quote_and_sd_order: 27.16,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 16",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr17",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.17,
    pw_system_quote_and_sd_order: 27.17,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 17",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr18",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.18,
    pw_system_quote_and_sd_order: 27.18,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 18",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr19",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.19,
    pw_system_quote_and_sd_order: 27.19,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 19",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr20",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.2,
    pw_system_quote_and_sd_order: 27.2,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 20",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr21",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.21,
    pw_system_quote_and_sd_order: 27.21,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 21",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr22",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.22,
    pw_system_quote_and_sd_order: 27.22,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 22",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr23",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.23,
    pw_system_quote_and_sd_order: 27.23,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 23",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr24",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.24,
    pw_system_quote_and_sd_order: 27.24,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 24",
    value_type: "Currency (7,0)"
  },
  {
    access_control: null,
    data_variable_element: "est_bill_savings_yr25",
    default_ui_visibility_pw_quote_and_sd_output: "Yes",
    default_ui_visibility_pw_quote_only_output: "Yes",
    input_output: "Output",
    pw_quote_output_only_order: 25.25,
    pw_system_quote_and_sd_order: 27.25,
    root: null,
    sf_object: "Quote",
    source: "Pricing Widget",
    ui_name: "Estimated Bill Savings Year 25",
    value_type: "Currency (7,0)"
  },
];
