/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import React, { useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  Button
} from '@mui/material';
import LoadingModal from '../../components/CustomModal/LoadingModal';
import { OptimizationType, convertPercentToDecimal, createValidationSchema, decimalPctgToString, formFields, shouldRenderInput, stringToEscalator, hasEpcComponentCosts } from './helpers';
import { InputFormPayload } from './types';
import DecimalTextField from './FieldTypes/DecimalField';
import SelectorField from './FieldTypes/SelectField';
import useOptimizeMutation, { QuoteWithSDDTO, QuoteWithoutSDDTO } from '../../api/useOptimizeInputs';
import { toast } from 'react-toastify';

export const INITIAL_DEV_FEE = 0.369;

const CalculatorForm = ({
  action,
  prevFormData,
  selectedSystemDesign,
  opportunityState,
  setInputs,
  setOutputDestination,
  systemDesignData,
  opportunityData,
  opportunityId,
  calculatorVersion,
  setOutputs,
  setShowOutputs,
  setLastAction,
  sdIsEngineered,
}: {
  action: 'view' | 'edit' | 'clone' | 'new';
  prevFormData?: InputFormPayload;
  selectedSystemDesign: string | null;
  opportunityState: string;
  systemDesignData: any;
  opportunityData: any;
  opportunityId: string;
  calculatorVersion: string;
  setInputs: (data: any) => void;
  setOutputs: (data: any) => void;
  setShowOutputs: (show: boolean) => void;
  setLastAction: (action: string) => void;
  setOutputDestination: (destination: string) => void;
  epcComponentCosts: any[];
  sdIsEngineered: boolean;
}) => {
  const currentFormState = useRef<typeof initialValues>();
  const optimizeMutation = useOptimizeMutation(!!selectedSystemDesign);
  const initialValues = {
    ac_system_size_estimated: prevFormData?.ac_system_size_estimated || ((prevFormData?.pv_size_dc_kw_sd || 0 ) * (prevFormData?.ac_system_size_multiplier || 1.25)) || 0,
    ac_system_size_kw: prevFormData?.ac_system_size_kw,
    ac_system_size_multiplier: prevFormData?.ac_system_size_multiplier || 1.25,
    avoided_cost_per_kwh_qt: prevFormData?.avoided_cost_per_kwh_qt,
    carport_pv_dc_kw: systemDesignData?.carport_pv_dc_kw__c,
    custom_opp_maintain_cost_applies: prevFormData?.custom_opp_maintain_cost_applies || 'No',
    custom_opp_maintain_cost_escal: prevFormData?.custom_opp_maintain_cost_escal,
    custom_opp_maintain_cost_yr1_per_kw: prevFormData?.custom_opp_maintain_cost_yr1_per_kw,
    custom_opp_maintain_cost_yr6_per_kw: prevFormData?.custom_opp_maintain_cost_yr6_per_kw,
    custom_useful_life_term: prevFormData?.custom_useful_life_term,
    disc_to_pre_system_utility_bill_pcnt_inp: decimalPctgToString(prevFormData?.disc_to_pre_system_utility_bill_pcnt_inp),
    epc_total_per_watt_inp_qt: prevFormData?.epc_total_per_watt_inp_qt,
    epc_total_per_watt_inp_sd: prevFormData?.epc_total_per_watt_inp_sd || prevFormData?.epc_total_per_watt_inp_qt || prevFormData?.epc_total_per_watt_sd,
    epc_total_per_watt_sd: Number((prevFormData?.epc_total_per_watt_sd || prevFormData?.epc_total_per_watt_sd_dep || 0).toFixed(2)),
    financing_type: prevFormData?.financing_type,
    ground_mount_pv_dc_kw: systemDesignData?.ground_mount_pv_dc_kw__c,
    itc_adder_domestic_qt: prevFormData?.itc_adder_domestic_qt || 'No',
    itc_adder_energy_com_qt: prevFormData?.itc_adder_energy_com_qt || 'No',
    itc_adder_low_inc_qt: prevFormData?.itc_adder_low_inc_qt || 'No',
    itc_ineligible_nom_qt: prevFormData?.itc_ineligible_nom_qt,
    kwh_per_kw_qt: prevFormData?.kwh_per_kw_qt,
    kwh_per_kw_sd: prevFormData?.kwh_per_kw_sd_dep || prevFormData?.kwh_per_kw_sd || prevFormData?.kwh_per_kw_qt || systemDesignData?.kwh_per_kw_sd_flow__c,
    lease_escal_orig_term: stringToEscalator(prevFormData?.lease_escal_orig_term || '0.0199') ,
    lease_term_yrs_num: prevFormData?.lease_term_yrs_num || "25",
    offtaker_holds_rec_rights: prevFormData?.offtaker_holds_rec_rights || 'No',
    ppa_escal_orig_term: stringToEscalator(prevFormData?.ppa_escal_orig_term || '0.0199'),
    ppa_prod_guar_included: prevFormData?.ppa_prod_guar_included || 'No',
    ppa_term_yrs_num: prevFormData?.ppa_term_yrs_num || "25",
    public_entity: prevFormData?.public_entity || 'No',
    public_school: prevFormData?.public_school || 'No',
    pv_size_dc_kw_qt: prevFormData?.pv_size_dc_kw_qt,
    pv_size_dc_kw_sd: prevFormData?.pv_size_dc_kw_sd || prevFormData?.pv_size_dc_kw_qt,
    rec_group: prevFormData?.rec_group,
    rec_type: prevFormData?.rec_type,
    rooftop_pv_dc_kw: systemDesignData?.rooftop_pv_dc_kw__c,
    site_lease_annual_nom: prevFormData?.site_lease_annual_nom,
    site_lease_escal: stringToEscalator(prevFormData?.site_lease_escal),
    site_lease_included: prevFormData?.site_lease_included || 'No',
    site_lease_term: prevFormData?.site_lease_term,
    sr_finl_dev_fee_per_watt_inp: prevFormData?.sr_finl_dev_fee_per_watt_inp || INITIAL_DEV_FEE,
    state_incentive_yr1_nom_qt: prevFormData?.state_incentive_yr1_nom_qt || prevFormData?.state_incentive_yr1_nom_sd,
    state_incentive_yr1_nom_sd: prevFormData?.state_incentive_yr1_nom_sd,
    utility_name: prevFormData?.utility_name,
  };

  const handleSubmit = async (values: Partial<InputFormPayload>, type: OptimizationType, { setSubmitting, validateForm }) => {
    validateForm();
    setSubmitting(true);
    const payload = selectedSystemDesign ? {
      custom_opp_maintain_cost_escal: '',
      custom_opp_maintain_cost_yr1_per_kw: '',
      custom_opp_maintain_cost_yr6_per_kw: '',
      guaranty_pcnt_sd: '',
      inverter_extended_warr_per_watt: '',
      lease_escal_orig_term: '',
      lease_term_yrs_num: '',
      opportunity_id: opportunityId,
      partner_contract_form_applies: opportunityData.partner_contract_form_applies__c,
      ppa_escal_orig_term: '',
      ppa_term_yrs_num: '',
      prepay_nom_cust: 0,
      pricing_model_error: '',
      pricing_model_upfront_incentive: '',
      project_state: opportunityState,
      public_entity: 'No',
      rec_type: '',
      s3_link: '',
      sales_channel: opportunityData.sales_channel__c,
      site_lease_annual_nom: '',
      site_lease_escal: '',
      site_lease_term: '',
      solver: 'Solver_Discount_PW',
      support_structure_type_primary_qt: opportunityData.support_structure_type_primary_qt__c,
      system_design: selectedSystemDesign,
      utility_name: opportunityData.utility_name__c,
      ...systemDesignData,
      ...values,
      epc_total_per_watt_sd: values.epc_total_per_watt_sd || prevFormData?.epc_total_per_watt_sd,
      epc_total_per_watt_inp_sd: values.epc_total_per_watt_sd || prevFormData?.epc_total_per_watt_inp_sd || prevFormData?.epc_total_per_watt_sd,
      ...(values.disc_to_pre_system_utility_bill_pcnt_inp ? { disc_to_pre_system_utility_bill_pcnt_inp: convertPercentToDecimal(values.disc_to_pre_system_utility_bill_pcnt_inp) } : {}),
    } as QuoteWithSDDTO : {
      project_state: opportunityState,
      system_design: selectedSystemDesign || null,
      sales_channel: opportunityData.sales_channel__c,
      support_structure_type_primary_qt: opportunityData.support_structure_type_primary_qt__c,
      opportunity_id: opportunityId,
      utility_name: opportunityData.utility_name__c,
      partner_contract_form_applies: opportunityData.partner_contract_form_applies__c,
      s3_link: '',
      site_lease_annual_nom: '',
      pricing_model_upfront_incentive: '',
      pricing_model_error: '',
      public_entity: 'No',
      prepay_nom_cust: 0,
      lease_escal_orig_term: '',
      lease_term_yrs_num: '',
      site_lease_term: '',
      guaranty_pcnt_sd: '',
      custom_opp_maintain_cost_yr1_per_kw: '',
      custom_opp_maintain_cost_yr6_per_kw: '',
      custom_opp_maintain_cost_escal: '',
      rec_type: '',
      solver: 'Solver_Discount_PW',
      site_lease_escal: '',
      ppa_escal_orig_term: '',
      ppa_term_yrs_num: '',
      ...systemDesignData,
      ...values,
      ...(values.disc_to_pre_system_utility_bill_pcnt_inp ? { disc_to_pre_system_utility_bill_pcnt_inp: convertPercentToDecimal(values.disc_to_pre_system_utility_bill_pcnt_inp) } : {}),
    } as QuoteWithoutSDDTO;

    const data = await optimizeMutation.mutateAsync({ data: payload, optimizationType: type, calculatorVersion }, {
      onError: (error) => {
        console.error('Optimization error:', error);
        toast.warning('Failed to optimize with Error: pricing_model_error. Check the inputs and try again', {
          position: toast.POSITION.TOP_RIGHT
        });
        setShowOutputs(false)
        setSubmitting(false);
      },
    });
    setInputs({...values});
    setOutputs(data.result)
    setOutputDestination(data.path);
    setShowOutputs(true)
    setLastAction(`optimize-${type}`)
    setSubmitting(false);
  };

  const renderField = (field, errors, touched, values, isViewOnly: boolean) => {
    const isDisabled = isViewOnly || field.disabled || (field.getDisabled && field.getDisabled(values, systemDesignData));
    const helperNote = field.helperNote || (field.getHelperNote && field.getHelperNote(values, systemDesignData));

    switch (field.type) {
      case 'select':
        return (
          <SelectorField
            name={field.name}
            label={field.label}
            options={field.options}
            defaultValue={field.defaultValue}
            isViewOnly={isViewOnly}
            isRequired={field.isRequired}
          />
        );
      case 'number':
        return (
          <DecimalTextField
            name={field.name}
            label={field.label}
            isPercentage={field.isPercentage}
            withoutDollarSign={field.withoutDollarSign}
            decimals={field.decimals}
            disabled={isDisabled}
            isRequired={field.isRequired}
            helperNote={helperNote}
            isTouched={touched[field.name]}
          />
        );
      case 'text':
        return (
          <Field
            name={field.name}
            label={field.label}
            disabled={field.disabled || isViewOnly}
            fullWidth
            margin="normal"
            type="text"
            as={TextField}
            InputLabelProps={{ sx: { fontWeight: 'bold', color: touched[field.name] ? 'black' : 'primary' } }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Card sx={{ maxWidth: 600, margin: 'auto' }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Solar Project Form
        </Typography>
        <LoadingModal
          show={optimizeMutation.isPending}
        />
        <Formik
          initialValues={initialValues}
          validationSchema={() => createValidationSchema(currentFormState.current, selectedSystemDesign, opportunityState, systemDesignData)}
          onSubmit={() => {}}
          validateOnChange
          validateOnmount
        >
          {({ errors, touched, values, dirty, isSubmitting, isValid, setSubmitting, setFieldValue, validateForm}) => {
          currentFormState.current = values;

          useEffect(() => {
            if (hasEpcComponentCosts(systemDesignData)) {
              const pvSystemSizeKw =
                values.pv_size_dc_kw_sd || values.pv_size_dc_kw_qt || 0;
              const epcValue = calculateEpcTotalPerWattSD(
                systemDesignData,
                pvSystemSizeKw
              );
              setFieldValue('epc_total_per_watt_sd', epcValue);
            }
          }, [
            values.pv_size_dc_kw_sd,
            values.pv_size_dc_kw_qt,
            systemDesignData,
            setFieldValue,
          ]);


          const optimizedEpcDisabled = (action === 'view' || isSubmitting || !dirty || sdIsEngineered || (!isValid && dirty)) && action !== 'clone' || !isValid;
          return(
            <Form>
              <Box>
                {formFields.map((field) => {
                  const isViewOnly = (action === 'view' || field.disabled || (!!field.disabledOnSD && !!selectedSystemDesign));
                  const shouldRender = shouldRenderInput(field, values, selectedSystemDesign, opportunityState, systemDesignData);
                  if (!shouldRender && values[field.name]) setFieldValue(field.name, '');
                  return shouldRender ? renderField(field, errors, touched, values, isViewOnly) : null;
                })}
              </Box>
              <Box mt={2} display="flex" flex={1} justifyContent="end">
                <Button
                  variant="contained"
                  color='primary'
                  onClick={() => handleSubmit(values, 'discount', { setSubmitting, validateForm })}
                  style={{ color: 'white' }}
                  disabled={(action === 'view' || isSubmitting || !dirty || (!isValid && dirty)) && action !== 'clone' || !isValid}
                >
                  Optimized Discount
                </Button>
                <Button
                  variant="contained"
                  color='primary'
                  style={{ marginLeft: 10, marginRight: 10, color: 'white' }}
                  onClick={() => handleSubmit(values, 'dev', { setSubmitting, validateForm })}
                  disabled={(action === 'view' || isSubmitting || !dirty || (!isValid && dirty)) && action !== 'clone' || !isValid}
                >
                  Optimized Dev Fee
                </Button>
                {
                    <Button
                      variant="contained"
                      color='primary'
                      style={{ color: 'white' }}
                      onClick={() => handleSubmit(values, 'epc', { setSubmitting, validateForm })}
                      disabled={optimizedEpcDisabled}
                    >
                      Optimized EPC
                    </Button>
                }
              </Box>
            </Form>
          )}}
        </Formik>
      </CardContent>
    </Card>
  );
};

const calculateEpcTotalPerWattSD = (
  systemDesignData: any,
  pvSystemSizeKw: number
): number => {
  const {
    Installer_Cost_Nom__c = 0,
    Sunrock_Equip_Cost_Nom__c = 0,
    Sunrock_Dev_Cost_Nom__c = 0,
    Project_Development_Cost_Nom__c = 0,
  } = systemDesignData;

  const totalComponentCosts =
    Installer_Cost_Nom__c +
    Sunrock_Equip_Cost_Nom__c +
    Sunrock_Dev_Cost_Nom__c +
    Project_Development_Cost_Nom__c;

  return pvSystemSizeKw > 0
    ? totalComponentCosts / (pvSystemSizeKw * 1000)
    : 0;
};

export default CalculatorForm;
