import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { OptimizationType } from '../components/CalculatorForm/helpers';
import { toast } from 'react-toastify';

const excludeFromOverride = new Set([
  "epc_total_per_watt_sd__c",
  "epc_total_per_watt_inp_sd__c",
]);

const useOptimizeMutation = (hasSystemDesign: boolean,) => {
  const endpoint = hasSystemDesign ? '/api/optimize-with-sd' : '/api/optimize-without-sd';

  return useMutation({
    mutationFn: async ({ data, optimizationType, calculatorVersion } : {
      data:  QuoteWithoutSDDTO | QuoteWithSDDTO,
      optimizationType: OptimizationType,
      calculatorVersion: string
    }) => {
        const optimizationParams = {
          optimization_type: optimizationType,
          version: calculatorVersion
        };

        const urlSearchParams = new URLSearchParams(location.search);
        const fm = urlSearchParams.get("freezemodel");
        if (fm == "true") {
          optimizationParams["freezemodel"] = true;
        }

        Object.keys(data).forEach((key) => {
          if (key.endsWith('__c')) {
            const newKey = key.slice(0, -3);
            if (!excludeFromOverride.has(key)) {
                data[newKey] = data[key];
            }
            delete data[key];
          }
        });

        // if data value is a numeric string, convert to number
        // except for ppa_escal_orig_term and ppa_term_yrs_num
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === 'string' && !isNaN(parseFloat(data[key])) && data[key] !== '') {
            if (key !== 'ppa_escal_orig_term' && key !== 'ppa_term_yrs_num' && key !== 'opportunity_id') {
              data[key] = parseFloat(data[key]);
            }
          }
        });

        // compare data with QuoteWithSDDTO and QuoteWithoutSDDTO, and set "" default values when key is missing
        const defaultValues = {
          pv_fcst_prod_yr1_jan_1: "",
          pv_fcst_prod_yr1_feb_2: "",
          pv_fcst_prod_yr1_mar_3: "",
          pv_fcst_prod_yr1_apr_4: "",
          pv_fcst_prod_yr1_may_5: "",
          pv_fcst_prod_yr1_jun_6: "",
          pv_fcst_prod_yr1_jul_7: "",
          pv_fcst_prod_yr1_aug_8: "",
          pv_fcst_prod_yr1_sep_9: "",
          pv_fcst_prod_yr1_oct_10: "",
          pv_fcst_prod_yr1_nov_11: "",
          pv_fcst_prod_yr1_dec_12: "",
          sr_equip_cost_nom: "",
          installer_cost_nom: "",
          sr_dev_cost_nom: "",
          additional_epc_cost_nom: "",
          third_party_dev_cost_nom: "",
          additional_epc_cost_per_watt: "",
          installer_cost_per_watt: "",
          sr_dev_cost_per_watt: "",
          sr_equip_cost_per_watt: "",
          third_party_dev_cost_per_watt: "",
          rec_type: "",
          rec_group: "",
          public_school: "",
          ac_system_size_kw: "",
          system_design: "",
          utility_name: "",
          disc_to_pre_system_utility_bill_pcnt_inp: "",
          pv_size_dc_kw_qt: "",
          pv_size_dc_kw_sd: "",
          kwh_per_kw_sd: "",
          epc_total_per_watt_qt: "",
          epc_total_per_watt_sd: "",
          avoided_cost_per_kwh_qt: "",
          itc_ineligible_nom_qt: "",
          custom_opp_maintain_cost_yr1_per_kw: "",
          custom_opp_maintain_cost_yr6_per_kw: "",
          custom_opp_maintain_cost_escal: "",
          prepay_nom_cust: "",
          degradation_pcnt: "",
          lease_term_yrs_num: "",
          lease_escal_orig_term: "",
          ppa_term_yrs_num: "",
          ppa_escal_orig_term: "",
          public_entity: "No",
          site_lease_annual_nom: 0,
          site_lease_term: "",
          site_lease_escal: "",
          degredation_pcnt: "",
          ac_system_size_estimated: "",
          state_incentive_yr1_nom_sd: "",
          support_structure_type_primary_sd: "",
        }

        Object.keys(defaultValues).forEach((key) => {
          if (data[key] === undefined) {
            data[key] = defaultValues[key];
          }
        });

        // if ac_system_size_kw is 0, set it to '', same for ac_system_size_estimated
        if (data.ac_system_size_kw === 0) {
          data.ac_system_size_kw = '';
        }

        if (data.ac_system_size_estimated === 0) {
          data.ac_system_size_estimated = '';
        }

        if (data.ppa_prod_guar_included === '') {
          data.ppa_prod_guar_included = 'No';
        }

        const payload = {
          ...data,
          optimization_params: optimizationParams
        };

        const result = await axios.post(endpoint, payload);
        return result.data;
      },
      onError: (error) => {
        console.error('Optimization error:', error);
        toast.warning('Failed to optimize with Error: pricing_model_error. Check the inputs and try again', {
          position: toast.POSITION.TOP_RIGHT
        });
      },
      onSuccess: () => {
        toast.success('Successfully optimized with no errors', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    },
  );
};

export default useOptimizeMutation;

interface OptimizationParams {
  optimization_type: 'epc' | 'dev' | 'discount';
  version?: string;
}

export interface SystemDesign {
  degradation_pcnt: number | string
  pv_fcst_prod_yr1_jan_1: number | string;
  pv_fcst_prod_yr1_feb_2: number | string;
  pv_fcst_prod_yr1_mar_3: number | string;
  pv_fcst_prod_yr1_apr_4: number | string;
  pv_fcst_prod_yr1_may_5: number | string;
  pv_fcst_prod_yr1_jun_6: number | string;
  pv_fcst_prod_yr1_jul_7: number | string;
  pv_fcst_prod_yr1_aug_8: number | string;
  pv_fcst_prod_yr1_sep_9: number | string;
  pv_fcst_prod_yr1_oct_10: number | string;
  pv_fcst_prod_yr1_nov_11: number | string;
  pv_fcst_prod_yr1_dec_12: number | string;
  sr_equip_cost_nom: number | string;
  installer_cost_nom: number | string;
  sr_dev_cost_nom: number | string
  additional_epc_cost_nom: number | string;
  third_party_dev_cost_nom: number | string;
  additional_epc_cost_per_watt: number | string;
  installer_cost_per_watt: number | string;
  sr_dev_cost_per_watt: number | string;
  sr_equip_cost_per_watt: number | string;
  third_party_dev_cost_per_watt: number | string;
  error?: string;
}

export interface QuoteWithSDDTO extends SystemDesign {
  optimization_params: OptimizationParams;
  lease_term_yrs_num: number;
  lease_escal_orig_term: number;
  ppa_term_yrs_num: number;
  ppa_escal_orig_term: number;
  financing_type: string;
  itc_adder_domestic_qt: string;
  itc_adder_energy_com_qt: string;
  itc_adder_low_inc_qt: string;
  sales_channel: string;
  project_state: string;
  disc_to_pre_system_utility_bill_pcnt_inp: number;
  sr_finl_dev_fee_per_watt_inp: number;
  support_structure_type_primary_qt: string;
  ppa_prod_guar_included: string;
  ac_system_size_multiplier: number;
  utility_name: string;
  custom_useful_life_term: string;
  offtaker_holds_rec_rights: string;
  site_lease_included: string;
  site_lease_annual_nom: number;
  opportunity_id: string;
  // Fields specific to QuoteWithSDDTO
  guaranty_pcnt_sd: number;
  inverter_extended_warr_per_watt: number;
  pv_size_dc_kw_sd: number;
  state_incentive_yr1_nom_sd: number;
  support_structure_type_primary_sd: string;
  epc_total_per_watt_sd: number;
  kwh_per_kw_sd: number;
  rec_type: string;
  rec_group: string;
  public_school: string;
  ac_system_size_kw: number | string;
  ac_system_size_estimated: number | string;
  system_design: string;
  epc_total_per_watt_inp_sd: number;
}

export interface QuoteWithoutSDDTO {
  epc_total_per_watt_sd: never
  optimization_params: OptimizationParams;
  lease_term_yrs_num: number;
  lease_escal_orig_term: number;
  ppa_term_yrs_num: number;
  ppa_escal_orig_term: number;
  financing_type: string;
  itc_adder_domestic_qt: string;
  itc_adder_energy_com_qt: string;
  itc_adder_low_inc_qt: string;
  sales_channel: string;
  project_state: string;
  disc_to_pre_system_utility_bill_pcnt_inp: number;
  sr_finl_dev_fee_per_watt_inp: number;
  epc_total_per_watt_inp_sd: never;
  support_structure_type_primary_qt: string;
  ppa_prod_guar_included: string;
  ac_system_size_multiplier: number;
  utility_name: string;
  custom_useful_life_term: string;
  offtaker_holds_rec_rights: string;
  site_lease_included: string;
  site_lease_annual_nom: number;
  opportunity_id: string;
  rec_type: string;
  rec_group: string;
  public_school: string;
  ac_system_size_kw: number | string;
  ac_system_size_estimated: number | string;
}